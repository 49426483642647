import React, { useState, useEffect } from 'react';

import List from '@components/List';
import Card from '@components/Card';
import { Divider, Grid } from '@components/Grid';
import { format, parseISO, isSameDay } from 'date-fns';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import ComingSoon from '@components/ComingSoon';
import { makeGetRequest, makePostRequest } from '@helpers/requests';
import { HOSTING_DEPLOY, STORE_STATS_SALES } from '@helpers/api';
import { withSnackbar } from '@components/Snackbar';
import { Line } from 'react-chartjs-2';
import { navigate } from 'gatsby-link';
import Tags from '@components/Tags';
import Button from '@components/Button';

const Dashboard = ({ openSnackbar }) => {
    const [publishing, setPublishing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [deploys, setDeploys] = useState([]);
    const [sales, setSales] = useState(null);
    const [checkouts, setCheckouts] = useState(null);
    const [visitors, setVisitors] = useState(null);
    const [registered, setRegistered] = useState(null);
    const [orders, setOrders] = useState(null);
    const [engagement, setEngagement] = useState(null);
    const [conversations, setConversations] = useState(null);
    const website = useSelector(state => state.website);

    useEffect(() => {
        (async () => {
            if (website?._id) {
                try {
                    // Retrieving hosting deploys from the API
                    const { data: deployData } = await makeGetRequest(HOSTING_DEPLOY);
                    setDeploys(deployData);

                    if (website?.features?.store) {
                        const { data: salesData } = await makeGetRequest(STORE_STATS_SALES);
                        setSales(salesData);
                    }

                    // setCheckouts();
                    // setVisitors();
                    // setRegistered();
                    // setOrders();
                    // setEngagement();
                    // setConversations();
                } catch (error) {
                    console.log(error?.response ? error.response : error);
                } finally {
                    setLoading(false);
                }
            }
        })();
    }, []);

    if (!website._id) {
        return <ComingSoon />;
    }

    const handlePublish = async () => {
        try {
            setPublishing(true);
            await makePostRequest(HOSTING_DEPLOY);
            openSnackbar(`Successfully published ${website?.name ?? ''}.`);
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(error?.errorMessage ?? 'An error occurred publishing your changes.');
        } finally {
            setPublishing(false);
        }
    };

    const latestPublishedDeploy = deploys.reduce(
        (acc, cur) => (acc?.published_at ? acc : cur?.published_at ? cur : null),
        null
    );

    const statistics = [
        {
            key: 'unique-visitors',
            title: 'Unique Visitors',
            description: !!registered ? null : 'View all unique visits to your website.',
            value: visitors?.meta?.total,
            feature: website?.features?.users,
            link: '/users',
            colour: '#F41959',
            length: visitors?.meta?.length,
            data: visitors?.results?.map(result => result?.total),
        },
        {
            key: 'users-registered',
            title: 'Users Registered',
            description: !!registered ? null : 'View all users who have registered an account.',
            value: registered?.meta?.total,
            feature: website?.features?.users,
            link: '/users',
            length: registered?.meta?.length,
            data: registered?.results?.map(result => result?.total),
        },
        {
            key: 'sales',
            title: 'Sales',
            description: !!sales ? null : 'View sales total by all store orders made.',
            value: !!sales
                ? `${website?.store?.currency?.symbol}${(sales?.meta?.total / 100).toFixed(2)}`
                : null,
            feature: website?.features?.store,
            link: '/store/orders',
            colour: '#21A0CC',
            length: sales?.meta?.length,
            data: sales?.results?.map(result => result?.total),
        },
        {
            key: 'orders',
            title: 'Orders',
            description: !!orders
                ? null
                : 'View all your store orders made by your users or guests.',
            value: orders?.meta?.total,
            feature: website?.features?.store,
            link: '/store/orders',
            colour: 'limegreen',
            length: orders?.meta?.length,
            data: orders?.results?.map(result => result?.total),
        },
        {
            key: 'checkouts',
            title: 'Checkouts',
            description: 'View all your store checkouts made by your users or guests.',
            value: !!checkouts
                ? `${website?.store?.currency?.symbol}${(checkouts?.meta?.total / 100).toFixed(2)}`
                : null,
            feature: website?.features?.store,
            link: '/store/checkouts',
            colour: '#21A0CC',
            length: checkouts?.meta?.length,
            data: checkouts?.results?.map(result => result?.total),
        },
        {
            key: 'engagement',
            title: 'Engagement',
            value: engagement?.meta?.total,
            feature: website?.features?.chat,
            link: '/chat',
            colour: 'green',
            length: engagement?.meta?.length,
            data: engagement?.results?.map(result => result?.total),
        },
        {
            key: 'conversations',
            title: 'Conversations',
            value: conversations?.meta?.total,
            feature: website?.features?.chat,
            link: '/chat',
            colour: 'orange',
            length: conversations?.meta?.length,
            data: conversations?.results?.map(result => result?.total),
        },
        {
            key: 'editor',
            title: 'Editor',
            description: 'Edit all your site content.',
            value: null,
            feature: website?.features?.editor,
            link: '/editor',
            colour: null,
            length: null,
            data: null,
        },
        {
            key: 'forms',
            title: 'Forms',
            description: 'Create and edit your forms.',
            value: null,
            feature: website?.features?.enquiries,
            link: '/forms',
            colour: null,
            length: null,
            data: null,
        },
        {
            key: 'submissions',
            title: 'Submissions',
            description: 'View form submissions by users and guests.',
            value: null,
            feature: website?.features?.enquiries,
            link: '/forms/submissions',
            colour: null,
            length: null,
            data: null,
        },
    ]
        .map(statistic => (!!statistic?.feature ? statistic : null))
        .sort((a, b) => (a === null ? 1 : -1))
        .slice(0, 6)
        .map((statistic, index) => (
            <div
                key={statistic?.key ?? index}
                className={`${styles.statistic} ${styles[`statistic${index + 1}`]} ${
                    !statistic?.data ? styles.statisticNoData : ''
                }`}
            >
                {!!statistic && (
                    <Card
                        loading={loading}
                        style={{ height: '100%' }}
                        onClick={() => navigate(statistic?.link)}
                    >
                        <Card.Content>
                            <Statistic {...statistic} />
                        </Card.Content>
                    </Card>
                )}
            </div>
        ));

    return (
        <div>
            <Grid>
                <div className={styles.dashboard}>
                    {statistics}

                    <Card className={styles.overview} divider={false}>
                        <Card.Image
                            cropped
                            alt={`${website?.name ?? 'Your website'}'s preview.`}
                            src={latestPublishedDeploy?.screenshot_url}
                            position="right"
                        />
                        <Card.Title>{website?.name ?? 'Your website'}</Card.Title>
                        <Card.Description link={website?.domain}>
                            {website?.domain ?? ''}
                        </Card.Description>
                        {!!latestPublishedDeploy?.published_at && (
                            <Card.Content>
                                <p className={styles.lastPublished}>
                                    Last published{' '}
                                    {format(parseISO(latestPublishedDeploy?.published_at), 'PPP p')}
                                </p>
                            </Card.Content>
                        )}
                        <Card.Actions>
                            <Card.Actions.Action
                                disabled={!website?.netlify?.deployWebhook}
                                text="Publish"
                                onClick={handlePublish}
                                submitting={publishing}
                            />
                        </Card.Actions>
                    </Card>

                    <Card loading={!latestPublishedDeploy} className={styles.lastUpdated}>
                        <Card.Avatar />
                        <Card.Title
                            small
                            text={`Last updated on ${
                                !!latestPublishedDeploy
                                    ? format(parseISO(latestPublishedDeploy?.published_at), 'PPP p')
                                    : ''
                            }`}
                        />
                        <Card.Description text={latestPublishedDeploy?.title} />
                    </Card>

                    <Card className={styles.latestDeploys} divider={false}>
                        <Card.Title text="Latest Deploys" />
                        <Card.Content>
                            <List loading={loading}>
                                {deploys.slice(0, 5).map(deploy => (
                                    <List.Item key={deploy.id}>
                                        <List.Item.Column width={50}>
                                            <List.Item.Title
                                                small
                                                faded
                                                text={
                                                    !!deploy?.published_at &&
                                                    (isSameDay(
                                                        new Date(),
                                                        parseISO(deploy?.published_at)
                                                    )
                                                        ? format(
                                                              parseISO(deploy?.published_at),
                                                              'kk:mm'
                                                          )
                                                        : format(
                                                              parseISO(deploy?.published_at),
                                                              'dd/MM'
                                                          ))
                                                }
                                            />
                                        </List.Item.Column>
                                        <List.Item.Column important>
                                            <List.Item.Title small text={deploy?.title} />
                                        </List.Item.Column>
                                        <List.Item.Column.Right important width={32.5}>
                                            <Tags>
                                                <Tags.Tag
                                                    key="deploy-summary"
                                                    status={
                                                        deploy?.summary?.status === 'ready'
                                                            ? 'success'
                                                            : 'danger'
                                                    }
                                                >
                                                    {deploy?.summary?.status === 'ready'
                                                        ? 'Published'
                                                        : 'Failed'}
                                                </Tags.Tag>
                                            </Tags>
                                        </List.Item.Column.Right>
                                    </List.Item>
                                ))}
                            </List>
                        </Card.Content>
                    </Card>
                </div>
            </Grid>
        </div>
    );
};

export default withSnackbar(Dashboard);

const Statistic = ({ length, title = '', description, value = '', link, data, colour }) => {
    const chartOptions = buildChartOptions();
    const chartData = buildDataOptions(data, colour);

    return (
        <div className={`${styles.statisticContent} ${!value ? styles.statisticEmpty : ''}`}>
            {!!length && <small className={styles.statisticLabel}>{`Last ${length} days`}</small>}
            <div className={styles.statisticContainer}>
                <div className={styles.statisticText}>
                    <h3 className={styles.statisticTitle}>{title}</h3>
                    {!!description && <p className={styles.statisticDescription}>{description}</p>}
                    {!!value ? (
                        <p
                            className={`${styles.statisticValue} ${
                                !value ? styles.statisticValueEmpty : ''
                            }`}
                        >
                            {value}
                        </p>
                    ) : (
                        <div>
                            <Divider margin={2} />
                            <Button link={link} text={`View ${title}`} transparent />
                        </div>
                    )}
                </div>
                {!!data && (
                    <div className={styles.statisticGraph}>
                        <Line width={100} height={100} data={chartData} options={chartOptions} />
                    </div>
                )}
            </div>
        </div>
    );
};

function buildChartOptions() {
    return {
        legend: {
            display: false,
        },
        tooltips: {
            enabled: false,
        },
        scales: {
            xAxes: [
                {
                    display: false,
                },
            ],
            yAxes: [
                {
                    display: false,
                },
            ],
        },
        elements: {
            point: {
                radius: 0,
            },
        },
    };
}

function buildDataOptions(data = [75, 60, 55, 65], borderColor = '#634394') {
    return {
        labels: ['One', 'Two', 'Three', 'Four'],
        datasets: [
            {
                data,
                borderColor,
                lineTension: 0.5,
                backgroundColor: 'rgba(255,255,255)',
                pointBackgroundColor: '#fff',
                pointHoverRadius: 0,
                pointRadius: 0,
                pointHitRadius: 10,
            },
        ],
    };
}

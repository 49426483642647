import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { navigate } from 'gatsby-link';
import { format, parseISO } from 'date-fns';

import { Grid, Divider } from '@components/Grid';
import { makeGetRequest } from '@helpers/requests';
import { STORE_CHECKOUTS } from '@helpers/api';
import Card from '@components/Card';
import Pagination from '@components/Pagination';
import Command from '@components/Command';
import usePrevious from '@helpers/hooks/usePrevious';
import Empty from '@components/Empty';

import styles from './styles.module.scss';
import { withSnackbar } from '@components/Snackbar';

const Checkouts = ({ website, page, query, location, openSnackbar }) => {
    const [loading, setLoading] = useState(true);
    const [checkouts, setCheckouts] = useState([]);
    const [total, setTotal] = useState(null);
    const [queryTimeout, setQueryTimeout] = useState(null);
    const prevPage = usePrevious(page);
    const prevQuery = usePrevious(query);

    const setNewCheckouts = async () => {
        try {
            if (!loading) setLoading(true);
            const { data: checkoutsData } = await makeGetRequest(STORE_CHECKOUTS, {
                perPage: 10,
                pageNum: page,
                query: query || undefined,
            });
            setTotal(checkoutsData?.meta?.total);
            setCheckouts(checkoutsData?.results);
            setLoading(false);
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(error?.errorMessage ?? 'An error occurred when loading checkouts.');
        }
    };

    useEffect(() => {
        (async () => await setNewCheckouts())();
        return () => {
            clearTimeout(queryTimeout);
        };
    }, []);

    useEffect(() => {
        if (!prevPage || prevPage === page) return;
        setNewCheckouts();
    }, [page]);

    useEffect(() => {
        if (!prevQuery || prevQuery === query) return;
        clearTimeout(queryTimeout);
        setQueryTimeout(
            setTimeout(async () => {
                await setNewCheckouts();
            }, 750)
        );
    }, [query]);

    const handleUrlChange = (query = {}) => {
        navigate(
            `${location.pathname}?${queryString.stringify({
                ...queryString.parse(location.search),
                page: undefined,
                ...query,
            })}`
        );
    };

    return (
        <Fragment>
            <Command title="Checkouts">
                <Command.Filters>
                    <Command.Filters.Search
                        key="search"
                        onChange={e => handleUrlChange({ query: e.target.value })}
                        value={query}
                    />
                </Command.Filters>
            </Command>

            {loading || !!checkouts.length ? (
                <Grid>
                    <Card loading={loading}>
                        <Card.List>
                            {loading ? (
                                <Card.List.Item title description />
                            ) : (
                                // checkouts.map(({ _id, timestamp, data: { products } }) => (
                                checkouts.map(
                                    ({ _id, meta: { timestamp }, stage, billing, products }) => (
                                        <Card.List.Item
                                            key={_id}
                                            title={`${billing?.fullName ?? 'Guest'}`}
                                            onClick={() => navigate(`/store/checkouts/${_id}`)}
                                        >
                                            <Card.List.Item.Content>
                                                <ul className={styles.checkoutItems}>
                                                    <li>{`${products?.length} Product${
                                                        products?.length === 1 ? '' : 's'
                                                    }`}</li>

                                                    {!!products && !!products.length && (
                                                        <li>
                                                            {`
                                                        ${website.store.currency.symbol}${products
                                                                .filter(
                                                                    product => product?.data?.price
                                                                )
                                                                .reduce(
                                                                    (acc, curr) =>
                                                                        curr?.data?.price / 100 ??
                                                                        0 + acc,
                                                                    0
                                                                )
                                                                .toFixed(2)}
                                                        `}
                                                        </li>
                                                    )}
                                                    {!!billing && <li>{billing.email}</li>}
                                                </ul>
                                            </Card.List.Item.Content>
                                            <Card.List.Item.Position.Bottom.Left
                                                faded
                                                text={`${format(
                                                    parseISO(stage?.lastUpdated ?? timestamp),
                                                    'PPP p'
                                                )}`}
                                            />
                                            <Card.List.Item.Position.Bottom.Right
                                                faded
                                                text={`Id: ${_id}`}
                                            />
                                        </Card.List.Item>
                                    )
                                )
                            )}
                        </Card.List>
                    </Card>

                    {!loading && (
                        <Fragment>
                            <Divider />
                            <Pagination
                                meta
                                onChange={page => handleUrlChange({ page })}
                                pageTotal={checkouts?.length}
                                total={total}
                                page={page}
                                perPage={10}
                                metaLabel="Checkouts"
                            />
                        </Fragment>
                    )}
                </Grid>
            ) : !!query.length || queryTimeout ? (
                <Empty title="Nothing here" text="Your filters didn't seem to find anything." />
            ) : (
                <Empty title="Hold tight" text="You don't have any checkouts yet!" />
            )}
        </Fragment>
    );
};

export default withSnackbar(
    connect(({ website }, ownProps) => {
        const params = queryString.parse(ownProps.location.search, { parseNumbers: true });
        const page = params.page ?? 1;
        const query = params.query || '';
        return { website, page, query };
    })(Checkouts)
);

import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby-link';

import { withSnackbar } from '@components/Snackbar';
import {
	makeDeleteRequest,
	makeGetRequest,
	makePostRequest,
	makePutRequest,
} from '@helpers/requests';
import { USER_TYPES, USER_FIELD, USER_FIELDS } from '@helpers/api';
import Command from '@components/Command';
import { Col, Divider, Grid, Row } from '@components/Grid';
import List from '@components/List';
import { format, parseISO } from 'date-fns';
import Loader from '@components/Loader';
import Card from '@components/Card';
import Panes from '@components/Panes';
import Input from '@components/Input';
import Alert from '@components/Alert';
import Select from '@components/Select';
import Switch from '@components/Switch';
import Checkboxes from '@components/Checkboxes';
import Button from '@components/Button';
import Modal from '@components/Modal';
import Text from '@components/Text';
import usePrevious from '@helpers/hooks/usePrevious';

import styles from './styles.module.scss';
import Label from '@components/Label';
import Form from '@views/Users/Fields/Field/Form';
import Wrapper from '@views/Users/Fields/Field/Wrapper';

const Field = ({ openSnackbar, id }) => {
	const user = useSelector(({ user }) => user);
	const [loading, setLoading] = useState(true);
	const [field, setField] = useState(null);
	const [allAccountTypes, setAllAccountTypes] = useState(null);

	const types = [
		{
			value: 'string',
			label: 'Short Answer',
			supports: {
				multiple: true,
			},
		},
		{
			value: 'paragraph',
			label: 'Paragraph',
			supports: {
				multiple: true,
			},
		},
		{
			value: 'radio',
			label: 'Multiple Choice',
		},
		{
			value: 'checkbox',
			label: 'Checkboxes',
		},
		{
			value: 'dropdown',
			label: 'Dropdown',
		},
		{
			value: 'html',
			label: 'HTML',
			supports: {
				multiple: true,
			},
		},
		{
			value: 'image',
			label: 'Image',
			supports: {
				multiple: true,
			},
		},
		{
			value: 'switch',
			label: 'Switch',
		},
		{
			value: 'collection',
			label: 'Collection',
		},
	];

	useEffect(() => {
		(async () => {
			try {
				if (id) {
					const { data: fieldData } = await makeGetRequest(USER_FIELD(id));
					setField(fieldData);
				}

				const { data: accountTypesData } = await makeGetRequest(USER_TYPES, {
					perPage: 1000,
					pageNum: 1,
				});

				setAllAccountTypes(accountTypesData?.results);
			} catch (error) {
				if (error !== 'cancelled') {
					openSnackbar(
						error?.errorMessage ?? 'An error occurred attempting to load this field.'
					);

					navigate(`/users/fields`);
				}
			} finally {
				setLoading(false);
			}
		})();
	}, []);

	const handleSubmit = async values => {
		const { label } = values;

		try {
			const buildData = ({
				label,
				type,
				hint,
				disabled,
				id,
				options,
				accountTypes,
				optional,
				items,
				globalApplicability,
				multiple,
			}) => ({
				label,
				type,
				hint,
				disabled: disabled || false,
				id,
				options:
					type === 'checkbox' || type === 'radio' || type === 'dropdown'
						? options
						: undefined,
				accountTypes: globalApplicability ? undefined : accountTypes,
				optional,
				items: type === 'collection' ? items?.map(item => buildData(item)) : undefined,
				multiple,
			});

			const data = buildData(values);

			console.log({ data });

			const { data: fieldData } = !!field
				? await makePutRequest(USER_FIELD(field?._id), data)
				: await makePostRequest(USER_FIELDS, data);

			if (!id) {
				navigate(`/users/fields/${fieldData?._id}`);
			} else {
				setField(fieldData);
			}

			openSnackbar(`${label} field has been successfully ${!!field ? 'edited' : 'created'}.`);
		} catch (error) {
			console.log({ error });
			error !== 'cancelled' &&
				openSnackbar(
					error?.errorMessage ??
						`An error occurred ${!!field ? 'editing' : 'creating'} this field.`
				);
		}
	};

	const handleDelete = async () => {
		try {
			console.log({ id });
			await makeDeleteRequest(USER_FIELD(id));
			openSnackbar(`${field?.label} field has been successfully deleted.`);
			navigate('/users/fields');
		} catch (error) {
			console.log({ error });
			error !== 'cancelled' &&
				openSnackbar(error?.errorMessage ?? 'An error occurred deleting this field.');
		}
	};

	return (
		<Fragment>
			<Command>
				<Command.Breadcrumbs>
					<Command.Breadcrumbs.Breadcrumb text="Users" link="/users" />
					<Command.Breadcrumbs.Breadcrumb text="Fields" link="/users/fields" />
					<Command.Breadcrumbs.Breadcrumb text={field?.label ?? 'Field'} />
				</Command.Breadcrumbs>
			</Command>

			{loading ? (
				<Loader />
			) : (
				<Wrapper
					types={types}
					field={field}
					onSubmit={handleSubmit}
					onDelete={handleDelete}
				>
					{props => {
						const { values, setFieldValue } = props;

						const {
							id,
							label,
							type,
							hint,
							accountTypes,
							globalApplicability,
							disabled,
							optional,
							items,
							multiple,
						} = values;

						console.log('index.js', { accountTypes });

						const [creatingCollectionItem, setCreatingCollectionItem] = useState(false);
						const [activeCollectionItem, setActiveCollectionItem] = useState(null);

						// if field changes (from submission, reset nested collection item state).
						useEffect(() => {
							if (!field) return;
							setCreatingCollectionItem(false);
							setActiveCollectionItem(null);
						}, [field]);

						const handleCollectionSubmit = field => {
							const index = items?.findIndex(
								({ _id, collectionId }) =>
									_id === field?.collectionId ||
									collectionId === field?.collectionId
							);

							setFieldValue(
								`items.${index >= 0 ? index : items?.length ?? 0}`,
								field
							);
							setActiveCollectionItem(null);
							setCreatingCollectionItem(false);
						};

						const handleCollectionDelete = async field => {
							try {
								setFieldValue(
									'items',
									items?.filter(
										({ _id, collectionId }) =>
											_id !== field?.collectionId &&
											collectionId !== field?.collectionId
									)
								);
							} catch (error) {}
						};

						const handleActiveCollectionItem = activeField => {
							setActiveCollectionItem(activeField);
							setCreatingCollectionItem(true);
						};

						return (
							<Panes>
								<Panes.Left>
									<Form
										types={types}
										allAccountTypes={allAccountTypes}
										onActiveCollectionItem={handleActiveCollectionItem}
										creatingCollectionItem={creatingCollectionItem}
										{...props}
									/>
								</Panes.Left>
								<Panes.Right>
									{!!creatingCollectionItem ? (
										<Fragment>
											<Panes.Title
												onBack={() => {
													setActiveCollectionItem(null);
													setCreatingCollectionItem(false);
												}}
											>
												<h3>
													{activeCollectionItem?.label
														? `Editing item - ${activeCollectionItem.label}`
														: 'New item'}
												</h3>
											</Panes.Title>

											<Wrapper
												types={types}
												nested
												field={activeCollectionItem}
												onSubmit={handleCollectionSubmit}
												onDelete={handleCollectionDelete}
											>
												{props => (
													<Form
														types={types.filter(
															type => type?.value !== 'collection'
														)}
														activeCollectionItem={activeCollectionItem}
														onActiveCollectionItem={
															handleActiveCollectionItem
														}
														{...props}
													/>
												)}
											</Wrapper>
										</Fragment>
									) : (
										<Fragment>
											<List>
												{(!!field || !!user?.permissions?.isAdmin) && (
													<List.Item>
														<List.Item.Column>
															<Text bold>Id</Text>
														</List.Item.Column>
														<List.Item.Column.Right important>
															<Text>{id}</Text>
														</List.Item.Column.Right>
													</List.Item>
												)}

												<List.Item>
													<List.Item.Column>
														<Text bold>Applicable</Text>
													</List.Item.Column>
													<List.Item.Column.Right important>
														<Text>
															{globalApplicability
																? 'Global'
																: 'Account Type'}
														</Text>
													</List.Item.Column.Right>
												</List.Item>
												<List.Item>
													<List.Item.Column>
														<Text bold>Label</Text>
													</List.Item.Column>
													<List.Item.Column.Right important>
														<Text>{label}</Text>
													</List.Item.Column.Right>
												</List.Item>
												<List.Item>
													<List.Item.Column>
														<Text bold>Hint</Text>
													</List.Item.Column>
													<List.Item.Column.Right important>
														<Text>{hint}</Text>
													</List.Item.Column.Right>
												</List.Item>
												<List.Item>
													<List.Item.Column>
														<Text bold>Type</Text>
													</List.Item.Column>

													<List.Item.Column.Right important>
														<Text>
															{
																types.find(
																	({ value }) => value === type
																)?.label
															}
														</Text>
													</List.Item.Column.Right>
												</List.Item>
												{types?.find(({ value }) => value === type)
													?.supports?.multiple && (
													<List.Item>
														<List.Item.Column>
															<Text bold>Multiple</Text>
														</List.Item.Column>
														<List.Item.Column.Right important>
															<List.Item.Checked checked={multiple} />
														</List.Item.Column.Right>
													</List.Item>
												)}
												<List.Item>
													<List.Item.Column>
														<Text bold>Disabled</Text>
													</List.Item.Column>
													<List.Item.Column.Right important>
														<List.Item.Checked checked={disabled} />
													</List.Item.Column.Right>
												</List.Item>
												<List.Item>
													<List.Item.Column>
														<Text bold>Optional</Text>
													</List.Item.Column>
													<List.Item.Column.Right important>
														<List.Item.Checked checked={optional} />
													</List.Item.Column.Right>
												</List.Item>
												{!!field && (
													<Fragment>
														<List.Item key="timestamp">
															<List.Item.Column>
																<Text bold>Created</Text>
															</List.Item.Column>
															<List.Item.Column.Right important>
																<Text>
																	{!!field?.timestamp &&
																		format(
																			parseISO(
																				field?.timestamp
																			),
																			'PPP p'
																		)}
																</Text>
															</List.Item.Column.Right>
														</List.Item>
													</Fragment>
												)}
											</List>

											{!!accountTypes && !!accountTypes.length && (
												<Fragment>
													<Divider />
													<Card>
														<Card.Title text="Applicable Account Types" />
														<Card.Content>
															<List>
																{allAccountTypes
																	.filter(({ _id }) =>
																		accountTypes.some(
																			accountType =>
																				accountType === _id
																		)
																	)
																	.map(({ _id, id, name }) => (
																		<List.Item
																			key={_id}
																			link={`/users/types/${_id}`}
																		>
																			<List.Item.Title
																				small
																				text={name}
																			/>
																			<List.Item.Description
																				text={id}
																			/>
																		</List.Item>
																	))}
															</List>
														</Card.Content>
													</Card>
												</Fragment>
											)}
										</Fragment>
									)}
								</Panes.Right>
							</Panes>
						);
					}}
				</Wrapper>
			)}
		</Fragment>
	);
};

export default withSnackbar(Field);

import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { subcomponent } from '@helpers';

import styles from './styles.module.scss';
import { withSnackbar } from '@components/Snackbar';

const Tags = withSnackbar(({ onCreate, onRemove, children, openSnackbar }) => {
    return (
        <ul className={styles.tags}>
            {subcomponent(children, Tags.Tag, false, { onRemove })}
            {!!onCreate && <Tags.Create onCreate={onCreate} openSnackbar={openSnackbar} />}
        </ul>
    );
});

Tags.Tag = ({ children, text, onRemove, status }) => {
    return (
        <li
            tabIndex={!!onRemove ? 0 : null}
            className={`${styles.tagsTag} ${!!onRemove ? styles.tagsTagRemoveable : ''} ${
                status === 'success' ? styles.tagsTagSuccess : ''
            } ${status === 'warning' ? styles.tagsTagWarning : ''} ${
                status === 'danger' ? styles.tagsTagDanger : ''
            }`}
            onClick={!!onRemove ? () => onRemove(text || children) : undefined}
        >
            <span>{text || children}</span>
            {!!onRemove && <FontAwesomeIcon icon={['fas', 'times']} />}
        </li>
    );
};
Tags.Tag.displayName = 'Tag';

Tags.Create = ({ onCreate, openSnackbar }) => {
    const $createTag = useRef(null);
    const [creating, setCreating] = useState(false);
    const [value, setValue] = useState('');

    useEffect(() => {
        if ($createTag && $createTag.current) {
            $createTag.current.focus();
        }
    }, [creating]);

    const handleTagCreate = () => {
        if (!value) return;
        onCreate && onCreate(value);
        setValue('');
        setCreating(false);
    };

    const handleTagChange = e => {
        let value = e.target.value
            .toLowerCase()
            .split(' ')
            .join('');

        if (value.length > 20) {
            value = value.slice(0, 20);
            openSnackbar(`Tags can't be longer than 20 characters.`);
        }

        setValue(value);
    };

    return creating ? (
        <li className={`${styles.tagsTag} ${styles.tagsTagCreate} ${styles.tagsTagCreateCreating}`}>
            <input ref={$createTag} value={value} onChange={handleTagChange} />
            <FontAwesomeIcon tabIndex={0} icon={['fas', 'check']} onClick={handleTagCreate} />
        </li>
    ) : (
        <li
            tabIndex={0}
            className={`${styles.tagsTag} ${styles.tagsTagCreate}`}
            onClick={() => setCreating(true)}
        >
            Add
            <FontAwesomeIcon icon={['fas', 'plus']} />
        </li>
    );
};
Tags.Create.displayName = 'Create';

export default Tags;
